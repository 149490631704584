<template>
  <div class="d-flex justify-end align-center">
    <QueryFilterObserver v-bind="filters.category" v-if="categoriesForSelect.length" />
    <QueryFilterObserver v-bind="filters.vendor" v-if="vendorsForSelect.length" />
    <QueryFilterObserver v-bind="filters.stock_min" />
    <QueryFilterObserver v-bind="filters.stock_max" />
    <QueryFilterObserver v-bind="filters.has_binding" />
    <QueryFilterObserver v-bind="filters.search" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import QueryFilterObserver from '@/components/QueryFilterObserver';

export default {
  components: {
    QueryFilterObserver,
  },
  props: {
    source: { type: String, required: true },
  },
  computed: {
    ...mapGetters('reference', [
      'categoriesForSelect',
      'vendorsForSelect',
    ]),
    query() {
      return this.$route.query;
    },
    filters() {
      return {
        category: {
          component: 'v-autocomplete',
          name: 'inventory_item__category__id',
          attrs: {
            class: 'field category',
            label: 'Категория',
            items: this.categoriesForSelect,
            clearable: true,
            hideDetails: true,
          },
        },
        vendor: {
          component: 'v-autocomplete',
          name: 'inventory_item__vendor__id',
          attrs: {
            class: 'field bind ml-2',
            label: 'Бренд',
            items: this.vendorsForSelect,
            clearable: true,
            hideDetails: true,
          },
        },
        stock_min: {
          component: 'v-text-field',
          name: 'stock_min',
          attrs: {
            class: 'field stock ml-2',
            label: 'Остатки от',
            hideDetails: true,
            outlined: true,
            dense: true,
          },
        },
        stock_max: {
          component: 'v-text-field',
          name: 'stock_max',
          attrs: {
            class: 'field stock ml-2',
            label: 'Остатки до',
            hideDetails: true,
            outlined: true,
            dense: true,
          },
        },
        has_binding: {
          component: 'v-select',
          name: 'binding_status',
          attrs: {
            class: 'field bind ml-2',
            label: 'Связи',
            items: [
              { text: 'Со связями', value: 'bound' },
              { text: 'Без связей', value: 'unbound' },
            ],
            dense: true,
            clearable: true,
            hideDetails: true,
          },
        },
        search: {
          component: 'v-text-field',
          name: 'search',
          attrs: {
            class: 'field search ml-2',
            label: 'Найти товар',
            clearable: true,
            hideDetails: true,
            outlined: true,
            dense: true,
            appendIcon: 'mdi-magnify',
          },
        },
      };
    },
  },
  watch: {
    source: {
      immediate: true,
      async handler() {
        try {
          await Promise.all([
            this.$store.dispatch('reference/FETCH_CATEGORIES', this.source),
            this.$store.dispatch('reference/FETCH_VENDORS', this.source),
          ]);
        } catch (e) {
          if (!e.isAxiosError) throw e;
          this.$notify.showErrorMessage(e.response);
        }
      },
    },
  },
};
</script>

<style scoped>

.field {
  font-size: 12px;
}

::v-deep .v-label {
  font-size: 12px;
}

.category {
  max-width: 180px;
}

.stock {
  max-width: 120px;
}

.bind {
  max-width: 160px;
}

.search {
  width: 240px;
}
</style>
