<template>
  <v-select v-model="_value" :items="_listings" :disabled="isLoading" item-text="name" item-value="id" placeholder="Выберите листинг" return-object single-line dense outlined hide-details />
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    value: { type: Object, default: () => (null) },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState('feeds', ['listings']),
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    _listings() {
      return this.listings.map((listing) => ({
        id: listing.id,
        name: `${listing.marketplace.name} (${listing.region.name})`,
      }));
    },
  },
  created() {
    this.fetchListings();
  },
  methods: {
    ...mapActions('feeds', ['FETCH_LISTINGS']),
    async fetchListings() {
      this.isLoading = true;

      try {
        await this.FETCH_LISTINGS();
      } catch (e) {
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
