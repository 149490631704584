<template>
  <span class="price" v-if="_price">{{ _price }}&nbsp;{{ currency | symbolFromCurrency }}</span>
</template>

<script>
import formatPriceForHuman from '@/helpers/priceFormatting';

export default {
  name: 'AppPrice',
  props: {
    price: { type: [Number, String], default: null },
    currency: { type: String, default: null },
  },
  computed: {
    _price() {
      return formatPriceForHuman(this.price) || '0';
    },
  },
};
</script>

<style lang="scss" scoped>
.price {
  white-space: nowrap;
}
</style>
