<template>
  <div class="products-list">
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular size="42" indeterminate />
    </div>

    <v-col cols="12" class="my-15 text-center" v-else-if="hasError">
      Что-то пошло не так, попробуйте обновить страницу или <router-link to="/">перейти на главную</router-link>
    </v-col>

    <template v-else>
      <v-col cols="12" class="products-list__header px-0">
        <v-col cols="12" class="d-flex align-center">
          <div class="d-flex align-baseline">
            <div v-text="warehouse.owner.name" />
            <div class="caption text--secondary ml-4" v-text="`товаров: ${warehouseItems_count}`" v-if="warehouseItems_count" />
          </div>
          <v-spacer />
          <WarehouseItemsFilters :source="warehouse.owner.slug" />
        </v-col>
      </v-col>
      <v-col cols="12 pa-0">
        <WareHouseItemsList :page="page" :items-per-page="itemsPerPage" :warehouse="warehouse" />
      </v-col>
      <v-row class="mb-16 mt-6" v-if="pageCount">
        <v-col offset="4" cols="4">
          <v-pagination v-model="page" :length="pageCount" />
        </v-col>
        <v-col offset="3" cols="1">
          <v-select v-model="itemsPerPage" label="Количество строк" :items="itemsPerPageSelect" :menu-props="{ top: true, offsetY: true }" outlined dense />
        </v-col>
      </v-row>

      <WarehouseSelectedItemsActions v-if="isSuperuser && selectedWarehouseItems.length" />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import PaginationMixin from '@/mixins/PaginationMixin';

import WarehouseItemsFilters from '@/components/WarehouseItemsFilters';
import WareHouseItemsList from '@/components/WareHouseItems/WareHouseItemsList';
import WarehouseSelectedItemsActions from '@/components/WareHouseItems/WarehouseSelectedItemsActions';

export default {
  components: {
    WarehouseItemsFilters,
    WareHouseItemsList,
    WarehouseSelectedItemsActions,
  },
  mixins: [PaginationMixin],
  data() {
    return {
      itemsPerPage: 24,
      itemsPerPageSelect: [12, 24, 48, 60],
      isLoading: false,
      hasError: false,
    };
  },
  metaInfo() {
    const { warehouseName } = this;

    return {
      title: `${warehouseName} — Инвентарь`,
    };
  },
  computed: {
    ...mapState('warehouses', ['warehouse', 'warehouseItems_count', 'selectedWarehouseItems']),
    ...mapGetters('auth', ['isSuperuser']),
    warehouseId() {
      return this.$route.params.warehouseId;
    },
    pageCount() {
      return Math.ceil(this.warehouseItems_count / this.itemsPerPage);
    },
    warehouseName() {
      return this.warehouse?.owner.name || '';
    },
  },
  watch: {
    warehouseId: {
      immediate: true,
      async handler(warehouseId) {
        this.isLoading = true;
        this.hasError = false;

        try {
          await this.FETCH_WAREHOUSE(warehouseId);
        } catch (e) {
          this.hasError = true;
          if (!e.isAxiosError) throw e;
        } finally {
          this.isLoading = false;
        }
      },
    },
  },
  mounted() {
    this.setInitialPerPageSelection();
  },
  methods: {
    ...mapActions('warehouses', ['FETCH_WAREHOUSE']),
    setInitialPerPageSelection() {
      if (!this.isSuperuser) return;

      this.itemsPerPageSelect = this.itemsPerPageSelect.concat([100, 200]);
    },
  },
};
</script>

<style lang="scss" scoped>
.products-list {
  &__header {
    position: sticky;
    top: 0;
    padding-bottom: 16px;
    background-color: hsla(0, 0%, 100%, 1);
    border-bottom: solid 1px;
    border-bottom-color: hsla(0, 0%, 92%, 1);
    z-index: var(--z-index--sticky);
  }
}
</style>
