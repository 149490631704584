<template>
  <button :class="classes" :disabled="disabled" v-bind="$props" v-on="$listeners">
    <template v-if="text">
      {{ text }}
    </template>
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    text: { type: String, default: null },
    theme: { type: String, default: 'primary' },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    classes() {
      return [
        'app-button',
        `app-button--${this.theme}`,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.app-button {
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;

  &:disabled {
    opacity: 0.7;
  }

  &--primary {
    background: var(--primary-color);
    color: var(--white-color);
  }

  &--primary-light {
    background: var(--primary-light-color);
    color: var(--primary-color);
  }

  &--danger {
    background: var(--danger-color);
    color: var(--white-color);
  }

  &--danger-light {
    background: var(--danger-light-color);
    color: var(--danger-color);
  }
}
</style>
