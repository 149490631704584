<template>
  <div class="warehouse-items-list">
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular size="42" indeterminate />
    </div>
    <div class="py-16 text-center" v-else-if="!warehouseItems.length">
      Здесь ничего нет
    </div>
    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr class="products-list__row">
            <th v-if="isSuperuser">
              <v-checkbox v-model="toggleAllItems" color="black" />
            </th>
            <th class="text-left" v-text="'№'" width="2%" />
            <TableHeaderCell class="text-left" title="Артикул" :field="sortField.sku" width="5%" />
            <TableHeaderCell class="text-left" title="Название товара" :field="sortField.name" width="35%" />
            <TableHeaderCell class="text-left" title="Категория" :field="sortField.category" width="17%" />
            <TableHeaderCell class="text-left" title="Бренд" :field="sortField.vendor" width="8%" />
            <th class="text-right" v-html="'Ед.&nbsp;измерения'" width="10%" />
            <TableHeaderCell class="text-right" title="Остатки" :field="sortField.stock" width="10%" />
            <TableHeaderCell class="text-right" title="Цена" :field="sortField.price" width="5%" />
            <th class="text-right" v-html="'Артикул&nbsp;ГМ'" width="8%" />
            <TableHeaderCell class="text-right" title="Создан" :field="sortField.created" width="5%" />
          </tr>
        </thead>
        <tbody>
          <WarehouseItemsRow v-for="(item, index) in warehouseItems" :key="item.id" :item="item" :index="index" :index-for-human="indexForHuman(index)" :warehouse="warehouse" />
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

import TableHeaderCell from '@/components/common/TableHeaderCell';
import WarehouseItemsRow from '@/components/WareHouseItems/WarehouseItemsRow';

export default {
  components: {
    TableHeaderCell,
    WarehouseItemsRow,
  },
  props: {
    page: { type: Number, required: true },
    itemsPerPage: { type: Number, required: true },
    warehouse: { type: Object, default: null },
  },
  data() {
    return {
      isLoading: false,
      sortField: {
        id: 'id',
        price: 'price',
        stock: 'stock',
        delivery: 'delivery_delay_days',
        name: 'inventory_item__name',
        sku: 'inventory_item__sku',
        ean: 'inventory_item__ean',
        category: 'inventory_item__category__name',
        vendor: 'inventory_item__vendor__name',
        owner: 'inventory_item__owner__name',
        created: 'created',
      },
    };
  },
  computed: {
    ...mapState('warehouses', ['warehouseItems', 'selectedWarehouseItems']),
    ...mapGetters('auth', ['isSuperuser']),
    toggleAllItems: {
      get() {
        return this.warehouseItems.every((item) => this.selectedWarehouseItems.find((i) => i.id === item.inventory_item.id));
      },
      set(val) {
        const itemsToAdd = this.warehouseItems.map((item) => item.inventory_item);
        let nextItems = null;

        if (!val) {
          nextItems = this.selectedWarehouseItems.filter((selectedItem) => !itemsToAdd.find((i) => i.id === selectedItem.id));
        } else {
          nextItems = new Set([
            ...this.selectedWarehouseItems,
            ...itemsToAdd,
          ]);
        }

        this.SET_SELECTED_WAREHOUSE_ITEMS([...nextItems]);
      },
    },
    warehouseId() {
      return this.$route.params.warehouseId;
    },
    queryForItems() {
      return { id: this.warehouseId, params: { ...this.$route.query, page_size: this.itemsPerPage } };
    },
  },
  watch: {
    queryForItems: {
      deep: true,
      handler: debounce(async function () {
        this.fetchItems();
      }, 400),
    },
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('warehouses', ['FETCH_ITEMS']),
    ...mapMutations('warehouses', ['SET_SELECTED_WAREHOUSE_ITEMS']),
    async fetchItems() {
      this.isLoading = true;

      try {
        await this.FETCH_ITEMS(this.queryForItems);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = e.isAxiosCancelError;
        if (!e.isAxiosError && !e.isAxiosCancelError) throw e;
      }
    },
    indexForHuman(index) {
      return (this.page - 1) * this.itemsPerPage + index + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.warehouse-items-list {
  &__row {
    background-color: hsla(0, 0%, 98%, 1);
    transition: all 0.1 ease-in-out;
  }
}
</style>
