<template>
  <div class="mass-operations">
    <v-select v-model="_value" :items="operationsList" item-text="name" item-value="component" placeholder="Выберите операцию" return-object single-line dense outlined hide-details />

    <AddToListing v-model="_listing" v-if="_value && _value.id === 2" />
  </div>
</template>

<script>
import AddToListing from '@/components/MassOperations/AddToListing';

export default {
  components: {
    AddToListing,
  },
  props: {
    value: { type: Object, default: () => null },
    listing: { type: Object, default: () => null },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    _listing: {
      get() {
        return this.listing;
      },
      set(val) {
        this.$emit('update:listing', val);
      },
    },
    operationsList() {
      return [
        {
          id: 1,
          name: 'Разместить в инвентаре',
        },
        {
          id: 2,
          name: 'Добавить в листинг',
          component: 'AddToListing',
        },
        {
          id: 3,
          name: 'Удалить выбранные товары',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.mass-operations {
  display: flex;
  gap: 10px;
  max-width: 540px;
}
</style>
