<template>
  <FloatingActionsBar :selected-items="selectedWarehouseItems" confirm-button-text="Выполнить" :disabled="isDisabled" @confirm="processOperations" @close="clearAll">
    <MassOperations v-model="operation" :listing.sync="listing" />
  </FloatingActionsBar>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

import FloatingActionsBar from '@/components/FloatingActionsBar';
import MassOperations from '@/components/MassOperations/MassOperations';

const BIND_MASS_ID = 1;
const BIND_LISTING_ID = 2;
const REMOVE_ITEMS_MASS = 3;

export default {
  components: {
    FloatingActionsBar,
    MassOperations,
  },
  data() {
    return {
      operation: null,
      listing: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('warehouses', ['selectedWarehouseItems']),
    ...mapGetters('warehouses', ['getSelectedWarehouseItemsIds']),
    isDisabled() {
      return this.isLoading || !this.operation || (this.operation.id === BIND_LISTING_ID && !this.listing);
    },
  },
  methods: {
    ...mapActions('inventory_items', ['BIND_PRODUCT_MASS', 'ADD_TO_LISTING_MASS', 'REMOVE_ITEMS_MASS']),
    ...mapMutations('warehouses', ['SET_SELECTED_WAREHOUSE_ITEMS']),
    async massBind() {
      const confirmed = await this.$root.$confirm('Размещение товаров', 'Вы уверены, что хотите разместить выбранные товары?');

      if (!confirmed) return;

      this.isLoading = true;

      try {
        const message = await this.BIND_PRODUCT_MASS(this.getSelectedWarehouseItemsIds);

        this.$notify.showMessage({ message, color: 'success' });
        this.clearAll();
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
    async listingBind() {
      const confirmed = await this.$root.$confirm('Добавление товаров в листинг', `Вы уверены, что хотите добавить выбранные товары в листинг "${this.listing.name}"?`);

      if (!confirmed) return;

      this.isLoading = true;

      try {
        const message = await this.ADD_TO_LISTING_MASS({
          itemIds: this.getSelectedWarehouseItemsIds,
          listingId: this.listing.id,
        });

        this.$notify.showMessage({ message, color: 'success' });
        this.clearAll();
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
    async massRemoveItems() {
      const confirmed = await this.$root.$confirm('ОПЕРАЦИЯ НЕОБРАТИМА!', 'Вы уверены, что хотите удалить выбранные товары?');

      if (!confirmed) return;

      this.isLoading = true;
      const { owner } = this.selectedWarehouseItems[0]; // овнера можно взять у любого итема

      try {
        const message = await this.REMOVE_ITEMS_MASS({
          itemIds: this.getSelectedWarehouseItemsIds,
          ownerId: owner.id,
        });

        this.$notify.showMessage({ message, color: 'success' });
        this.clearAll();
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
    clearAll() {
      this.SET_SELECTED_WAREHOUSE_ITEMS([]);
    },
    processOperations() {
      if (this.operation.id === BIND_MASS_ID) return this.massBind();
      if (this.operation.id === BIND_LISTING_ID) return this.listingBind();
      if (this.operation.id === REMOVE_ITEMS_MASS) return this.massRemoveItems();
    },
  },
};
</script>
