<template>
  <tr class="warehouse-items-row" :class="{ 'warehouse-items-row--selectable': isSuperuser, 'warehouse-items-row--active': itemIsActive }" @click="toggleItem">
    <td v-if="isSuperuser">
      <v-checkbox :input-value="itemIsActive" color="black" @change="toggleItem" />
    </td>
    <td class="text-left text-caption warehouse-items-row__index" v-text="indexForHuman" />
    <td class="text-left text-caption">
      <Article :item="item.inventory_item" />
    </td>
    <td class="text-left text-caption">
      <TextWithPopoverImage :index="index" :name="item.inventory_item.name" :src="item.inventory_item.image_url" />
    </td>
    <td class="text-left text-caption" v-text="categoryName(item.inventory_item)" />
    <td class="text-left text-caption" v-text="vendorName(item.inventory_item)" />
    <td class="text-right text-caption" v-text="item.inventory_item.unit_localized" />
    <td class="text-right text-caption" v-text="item.stock" />
    <td class="text-right text-caption">
      <AppPrice :price="item.price" :currency="item.currency" />
    </td>
    <td class="text-right text-caption">
      <GmidLink :gmid="item.inventory_item.gmid" :region-slug="regionSlugForGmidLink" v-if="item.inventory_item.gmid" />
    </td>
    <td class="text-right text-caption" v-text="dateCreated" />
  </tr>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import AppPrice from '@/components/AppPrice';
import Article from '@/components/common/Product/Article';
import TextWithPopoverImage from '@/components/common/TextWithPopoverImage';
import GmidLink from '@/components/common/GmidLink';

const DATE_OPTIONS = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export default {
  components: {
    AppPrice,
    Article,
    TextWithPopoverImage,
    GmidLink,
  },
  props: {
    item: { type: Object, required: true },
    index: { type: Number, required: true },
    warehouse: { type: Object, default: null },
    indexForHuman: { type: Number, default: 0 },
  },
  computed: {
    ...mapState('warehouses', ['selectedWarehouseItems']),
    ...mapGetters('auth', ['isSuperuser']),
    regionSlugForGmidLink() {
      return this.warehouse?.region.slug ?? null;
    },
    itemIsActive() {
      return this.selectedWarehouseItems.find((item) => item.id === this.item.inventory_item.id);
    },
    dateCreated() {
      if (!this.item.created) return '–';
      const date = new Date(Date.parse(this.item.created));

      return `${date.toLocaleString('ru', DATE_OPTIONS)}`;
    },
  },
  methods: {
    ...mapMutations('warehouses', ['TOGGLE_SELECTED_WAREHOUSE_ITEMS']),
    categoryName(item) {
      return item?.category?.name ?? '';
    },
    vendorName(item) {
      return item?.vendor?.name ?? '';
    },
    toggleItem(event) {
      if (!this.isSuperuser) return;
      if (event.target?.tagName === 'A' || event.target?.className.includes('input')) return;

      this.TOGGLE_SELECTED_WAREHOUSE_ITEMS(this.item.inventory_item);
    },
  },
};
</script>

<style lang="scss" scoped>
.warehouse-items-row {
  background-color: hsla(0, 0%, 98%, 1);
  transition: background-color 0.1s;

  &--selectable {
    cursor: pointer;
  }

  &--active {
    background-color: hsl(0, 0%, 93%);
  }

  &__index {
    color: hsla(0, 0%, 0%, 0.24);
  }

  a {
    color: hsla(0, 0%, 0%, 0.87);
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
      text-decoration-color: inherit;
      text-decoration-style: solid;
      color: var(--v-primary-base);
    }
  }
}
</style>
