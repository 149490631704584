import accounting from 'accounting';

// eslint-disable-next-line arrow-body-style
const constructPrice = (price) => { // Format price to a value, understandable by a server
  return String(price)
    .replace(/[^\d\-]{1}/, '.'); // replace first non-digit character with dot, making 5,0 → 5.0
};

const formatPriceForHuman = (price) => { // Format a price to the human-readable value
  let number = accounting.formatNumber(constructPrice(price), 2, ' ', ',');
  number = number.replace(',00', '');
  if (number === '0') {
    number = '';
  }
  return number;
};

export default formatPriceForHuman;
