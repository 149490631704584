<template>
  <div class="floating-actions-bar">
    <div class="floating-actions-bar__inner">
      <div class="floating-actions-bar__column">
        <div class="floating-actions-bar__stats">
          {{ statsText }}
        </div>
        <slot />
      </div>
      <div class="floating-actions-bar__column">
        <AppButton class="floating-actions-bar__submit" :text="confirmButtonText" :disabled="disabled" @click.native="$emit('confirm')" v-if="confirmButtonText" />
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import * as plural from 'plural-ru';

import AppButton from '@/components/AppButton';

export default {
  components: {
    AppButton,
  },
  props: {
    selectedItems: { type: Array, required: true },
    confirmButtonText: { type: String, default: null },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    statsText() {
      const start = plural(this.selectedItems.length, 'Выбран', 'Выбрано');
      const end = plural(this.selectedItems.length, 'товар', 'товара', 'товаров');

      return `${start}: ${this.selectedItems.length} ${end}`;
    },
  },
  mounted() {
    if (window.Chatra) {
      window.Chatra('hide');
    }
  },
  beforeDestroy() {
    if (window.Chatra) {
      window.Chatra('show');
    }
  },
};
</script>

<style lang="scss" scoped>
.floating-actions-bar {
  position: sticky;
  padding: 14px 16px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  background-color: hsl(0, 0%, 100%);
  border: 1px solid hsla(0, 0%, 0%, 8%);
  box-shadow: 0 2px 8px hsla(0, 0%, 0%, 16%);
  border-radius: 8px;
  font-size: 12px;
  z-index: var(--z-index--over-sticky);

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__column {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__stats {
    margin-right: 20px;
  }

  &__submit {
    margin-right: 10px;
  }
}
</style>
