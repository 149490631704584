<template>
  <div class="text-with-popver-image" @mouseover="hover = true" @mouseleave="hover = false">
    <div :class="imageClasses" v-if="src && hover">
      <img class="text-with-popver-image__img" :src="src" :alt="name">
    </div>
    <div class="text-with-popver-image__text" v-text="name" />
  </div>
</template>

<script>
export default {
  props: {
    name: { type: String, required: true },
    src: { type: String, required: false, default: null },
    index: { type: Number, required: true },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    imageClasses() {
      return {
        'text-with-popver-image__image': true,
        'text-with-popver-image__image--position-top': this.index > 3,
        'text-with-popver-image__image--position-bottom': this.index <= 3,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.text-with-popver-image {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  &__image {
    padding: 5px;
    position: absolute;
    left: 0;
    width: 160px;
    height: 112px;
    background-color: hsl(0, 0%, 100%);
    border: 1px solid hsl(0, 0%, 0%, 8%);
    box-shadow: 0 2px 8px hsl(0, 0%, 0%, 16%);
    border-radius: 4px;
    z-index: var(--z-index);

    &--position-top {
      bottom: 100%;
    }

    &--position-bottom {
      top: 100%;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
