<template>
  <th class="header-cell" :class="{'header-cell--active': showSort}" @click="sort">
    <span>{{ title }}</span>
    <v-icon v-html="`mdi-chevron-${orderDirection}`" v-if="showSort" />
  </th>
</template>

<script>
export default {
  props: {
    title: { type: String, required: false, default: '' },
    field: { type: String, required: true },
  },
  computed: {
    orderName() {
      if (this.$route.query.ordering) return this.$route.query.ordering.replace('-', '');
      return '';
    },
    orderDirection() {
      if (this.$route.query.ordering && this.$route.query.ordering[0] !== '-') return 'up';
      if (this.$route.query.ordering && this.$route.query.ordering[0] === '-') return 'down';
      return '';
    },
    showSort() {
      return this.orderName === this.field;
    },
  },
  methods: {
    sort() {
      const query = { ...this.$route.query };
      switch (query.ordering) {
        case this.field:
          query.ordering = `-${this.field}`;
          break;
        case `-${this.field}`:
          delete query.ordering;
          break;
        default:
          query.ordering = this.field;
          break;
      }
      this.$router.replace({ query });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-cell {
  cursor: pointer;

  &:hover {
    color: var(--v-primary-base) !important;
  }

  &--active {
    color: hsla(227, 100%, 55%, 0.8) !important;
  }
}
</style>
